.App-header {
  background-color: #ffffff;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #18223a;
  font-weight: bold;
  background-image: url(/src/assets/svg/Recurso168.svg), url(/src/assets/svg/Recurso171.svg);
  background-size: 90%;
  background-repeat: no-repeat;
  background-position: center;
  /* text-shadow: 0px 0px 5px #000000; */
}

.App-header img {
  /* filter: drop-shadow(0px 0px 5px #000000); */
  width: 50%;
}

.App-link {
  color: #18223a;
  z-index: 1;
}

.svg-bg {
  width: 100%;
  position: absolute;
  opacity: 0.3;
}