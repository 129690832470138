@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 200;
  src: local('Lato'), url(../assets/fonts/Lato/Lato-Thin.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local('Lato'), url(../assets/fonts/Lato/Lato-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  src: local('Lato'), url(../assets/fonts/Lato/Lato-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  font-style: normal;
  src: url(../assets/fonts/Poppins/Poppins-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 200;
  font-style: normal;
  src: url(../assets/fonts/Poppins/Poppins-ExtraLight.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  font-style: normal;
  src: url(../assets/fonts/Poppins/Poppins-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 700;
  font-style: normal;
  src: url(../assets/fonts/Poppins/Poppins-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 800;
  font-style: normal;
  src: url(../assets/fonts/Poppins/Poppins-ExtraBold.ttf) format('truetype');
}

.title {
  font-family: 'Poppins', sans-serif;
}

.content {
  font-family: 'Lato', sans-serif;
}

#root {
  overflow-x: hidden;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}
